.root {
    background-image: url(/public/images/header-mobile.png);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100px;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
}

.root img {
   height: 40px;
}

@media screen and (min-width: 520px) {
    .root {
        background-image: url(/public/images/header-tablet.png);
        height: 140px;
    }

    .root img {
        height: 50px;
     }
}

@media screen and (min-width: 769px) {
    .root {
        background-image: url(/public/images/header-desktop.png);
        padding: 2rem 0;
    }
}

@media screen and (min-width: 992px) {
    .root img {
        height: 60px;
    }
}