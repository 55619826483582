.root {
    border: solid 1px var(--mainColor);
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 2rem 2rem 2rem;
    margin: 1rem 1.5rem;
}

.title {
    font-size: 1.75rem;
    text-align: center;
}

.linksList {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.linksListElement {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    min-width: 230px;
}

.icon {
    width: auto;
    height: 25px;
}

.link {
    color: var(--mainColor);
    text-decoration: none;
}

.link:hover {
    color: var(--secondColor);
}

.link p {
    position: relative;
}

.linksvgline {
    position: absolute;
    top: 165%;
    left: 0;
    overflow: hidden;
    margin: 0;
    width: 100%;
    transition: stroke-dashoffset 0.3s ease-in-out;
    transform: translateY(-90%);
    fill: none;
    stroke: var(--secondColor);
    stroke-width: 10;
    stroke-linecap: round;
    stroke-dasharray: 400px; 
    stroke-dashoffset: 400px;
}

.link:hover .linksvgline {
    stroke-dashoffset: 0;
}

.footer {
    background-image: url(/public/images/footer-mobile.png);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 1rem;
    margin-top: 1rem;
}

.footerLogo {
    display: none;
    height: 50px;
    margin-bottom: 1.5rem;
}

.footerText {
    font-size: 0.85rem;
    margin: 0;
}

.legalNotice {
    color: var(--mainColor);
    font-size: 0.85rem;
    text-decoration: none;
    margin: 0;
    cursor: pointer;
}

.legalNotice:hover {
    color: var(--secondColor);
}

@media screen and (min-width: 520px) {
    .footer {
        background-image: url(/public/images/footer-tablet.png);
        height: 200px;
        margin-top: 0;
    }

    .footerLogo {
        display: block;
    }
}

@media screen and (min-width: 530px) {
    .linksList {
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 1.25rem;
    }

    .linksListElement {
        min-width: unset;
    }
}

@media screen and (min-width: 769px) {
    .footer {
        background-image: url(/public/images/footer-desktop.png);
    }
}

@media screen and (min-width: 885px) {
    .root {
        flex-direction: row;
        justify-content: center;
        gap: 1.25rem;
    }

    .title {
        margin-bottom: 0;
        width: 100%;
    }
}

@media screen and (min-width: 992px) {
    .root {
        margin: 1rem 5rem 1rem 4rem;
    }
}