.loader {
    color: var(--mainColor);
    border-radius: 50%;
    display: block;
    width: 20px;
    height: 20px;
    position: relative;
    animation: fill 0.7s ease-in infinite alternate;
    animation-delay: 0.2s;
}

.loader::before , 
.loader::after {
    content: '';
    border-radius: 50%;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 30px;
    top: 0;
    animation: fill 0.7s ease-in infinite alternate;
}

.loader::after {
    left: auto;
    right: 30px;
    animation-duration: 0.7s;
    animation-delay: 0.4s;
}

@keyframes fill {
    0% {  box-shadow: 0 0 0 2px inset }
    100%{ box-shadow: 0 0 0 10px inset }
}
