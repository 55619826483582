.root {
    background-color: var(--backgroundColor);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0 2rem;
    margin-bottom: 3rem;
}

.container h1 {
    text-align: center;
}

.picture {
    width: 100%;
    margin-bottom: 2rem;
}

.backButton {
    color: var(--mainColor);
    border: solid 1px var(--mainColor);
    border-radius: 999px;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: fit-content;
    padding: 0rem 0.75rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.backButton:hover {
    color: var(--secondColor);
    transform: scale(1.03);
}

.backButton > p {
    margin: 0.5rem 0;
}

.footer {
    background-image: url(/public/images/footer-mobile.png);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 1rem;
    margin-top: 1rem;
}

.footerLogo {
    display: none;
    height: 50px;
    margin-bottom: 1.5rem;
}

.footerText {
    font-size: 0.85rem;
    margin: 0;
}

.legalNotice {
    color: var(--mainColor);
    font-size: 0.85rem;
    text-decoration: none;
    margin: 0;
    cursor: pointer;
}

.legalNotice:hover {
    color: var(--secondColor);
}

@media screen and (min-width: 520px) {
    .picture {
        width: 65%;
    }

    .footer {
        background-image: url(/public/images/footer-tablet.png);
        height: 200px;
        margin-top: 0;
    }

    .footerLogo {
        display: block;
    }
}

@media screen and (min-width: 769px) {
    .container {
        padding: 0;
    }

    .picture {
        width: 45%;
    }

    .footer {
        background-image: url(/public/images/footer-desktop.png);
    }
}