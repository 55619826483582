.root {
    background-color: var(--backgroundColor);
    padding: 1.5rem 2rem 0 2rem;
}

.root:last-child {
    margin-bottom: 0;
}

.header {
    margin-bottom: 2.5rem;
}

.header h1 {
    font-size: 2.2rem;
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 0.5rem;
}

.backButton {
    color: var(--mainColor);
    border: solid 1px var(--mainColor);
    border-radius: 999px;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: fit-content;
    padding: 0rem 0.75rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.backButton:hover {
    color: var(--secondColor);
    transform: scale(1.03);
}

.backButton > img {
    width: 20px;
    height: auto;
}

.backButton > p {
    margin: 0.5rem 0;
}

.link {
    color: var(--mainColor);
}

.link:hover {
    color: var(--secondColor);
}

.footer {
    background-color: var(--backgroundColor);
    padding-top: 4rem;
}

.footer div:first-of-type {
    margin: 1rem 2rem;
}