@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@font-face {
  font-family: 'Purple Smile';
  src: url('../public/fonts/purple_smile.ttf') format('truetype');
}

body {
  margin: 0;
  color: var(--mainColor);
  font-family: 'Outfit', sans-serif;
}

p, a, button {
  font-family: 'Outfit', sans-serif;
  font-size: 1rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Purple Smile', sans-serif;
}

div.slick-slide > div {
  padding: 1rem;
}

button.slick-prev:before,
button.slick-next:before {
  color: var(--mainColor) !important;
  font-size: 32px !important;
}