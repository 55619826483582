.root {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    margin-right: 1rem;
    z-index: 2;
}

.nav {
    border: 1px solid var(--mainColor);
    border-radius: 30px;
    width: 50px;
    backdrop-filter: blur(4px);
    transition: 0.6s ease-in-out;
}

.nav:hover {
    width: 150px;
    transition: 0.3s ease-in-out;
}

.linksList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    min-height: 200px;
    padding: 0.25rem 0.5rem;
    margin: 0;
}

.linksListElement {
    display: flex;
    justify-content: flex-end;
    list-style-type: none;
    padding: 0.5rem 0.25rem;
    transition: 0.3s ease-in-out;
}

.linksListElement:hover {
    background-color: rgba(100, 143, 162, 0.1);
    border-radius: 999px;
}

.link {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    cursor: pointer;
}

.dot {
    background-color: var(--mainColor);
    border-radius: 999px;
    display: inline-block;
    width: 15px;
    height: 15px;
}

.dotActive {
    background-color: var(--secondColor);
    border-radius: 999px;
    display: inline-block;
    width: 15px;
    height: 15px;
}

.text {
    color: var(--mainColor);
    font-size: 0.95rem;
    white-space: nowrap;
    opacity: 0;
    transition: 0.3s ease-in-out;
}

.linksList:hover .text {
    opacity: 100;
    transition: 0.6s ease-in-out;
}