.root {
    border-left: 1px solid var(--mainColor);
    display: flex;
    align-items: center;
    width: 125px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0 1.5rem 0 2.5rem;
    z-index: 1;
    backdrop-filter: blur(4px);
    transition: 0.8s ease-in-out;
}

.rootClosed {
    transform: translateX(300px);
}

.icon {
    width: 75px;
    height: 60px;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
    transform: translate3d(0, 0, 0);
}

.pathTop,
.pathMiddle,
.pathBottom {
    fill: none;
    transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    stroke-width: 25px;
    stroke-linecap: round;
    stroke: var(--mainColor);
    stroke-dashoffset: 0px;
}

.pathTop,
.pathBottom {
    stroke-dasharray: 240px 950px;
}

.pathMiddle {
    stroke-dasharray: 240px 240px;
}

.cross .pathTop,
.cross .pathBottom {
  stroke-dashoffset: -650px;
  stroke-dashoffset: -650px;
}

.cross .pathMiddle {
  stroke-dashoffset: -115px;
  stroke-dasharray: 1px 220px;
}

.mask {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.linksList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    min-height: 200px;
    padding: 0.25rem 0.5rem;
    margin: 0;
}

.linksListElement {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0.5rem 0.25rem;
    transition: 0.3s ease-in-out;
}

.linksListElement:hover {
    background-color: rgba(100, 143, 162, 0.1);
    border-radius: 999px;
}

.linksListElementActive {
    background-color: rgba(100, 143, 162, 0.1);
    border-radius: 999px;
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0.5rem 0.25rem;
    transition: 0.3s ease-in-out;
}

.link {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    cursor: pointer;
}

.text {
    color: var(--mainColor);
    font-size: 0.95rem;
}