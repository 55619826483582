.root {
    min-height: 70vh;
    display: flex;
    gap: 1rem;
    padding: 2rem;
}

.root h1 {
    font-size: 2.5rem;
    padding-top: 5rem;
}

.root span:nth-child(1) {
    margin-right: 1rem;
}

.root span:nth-child(2) {
    display: inline-block;
    position: relative;
}

.linksvgline {
    position: absolute;
    top: 135%;
    left: 0;
    overflow: hidden;
    margin: 0;
    width: 100%;
    transition: stroke-dashoffset 0.3s ease-in-out;
    transform: translateY(-90%);
    fill: none;
    stroke: var(--secondColor);
    stroke-width: 10;
    stroke-linecap: round;
    stroke-dasharray: 400px; 
    stroke-dashoffset: 0;
}

@media screen and (min-width: 769px) {
    .root h1 {
        font-size: 4rem;
    }
}

@media screen and (min-width: 992px) {
    .root {
        padding: 0 5rem 0 4rem;
    }
}