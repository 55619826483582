.root {
    background-color: var(--backgroundColor);
    padding: 0 2rem 2rem 2rem;
}

.rootLoading {
    background-color: var(--backgroundColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 2rem;
}

.backButton {
    color: var(--mainColor);
    border: solid 1px var(--mainColor);
    border-radius: 999px;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: fit-content;
    padding: 0rem 0.75rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.backButton:hover {
    color: var(--secondColor);
    transform: scale(1.03);
}

.backButton > img {
    width: 20px;
    height: auto;
}

.backButton > p {
    margin: 0.5rem 0;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;
}

.header h1 {
    font-size: 2.2rem;
    text-align: center;
    margin: 0;
}

.projectContainer {
    display: grid;
    justify-items: center;
}

.pictureContainer {
    border: solid 1px var(--mainColor);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    width: 250px;
    height: 250px;
    padding: 1rem;
    margin-top: 1rem;
}

.picture {
    border-radius: 25px;
    width: auto;
    height: 100%;
}

.description {
    white-space: pre-wrap;
    margin-top: 2rem;
    transition: 0.3s ease-in-out;
}

.linkWeb {
    color: var(--mainColor);
    border: solid 1px var(--mainColor);
    border-radius: 999px;
    text-decoration: none;
    display: inline-block;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    transition: 0.3s ease-in-out;
}

.linkWeb:hover {
    color: var(--secondColor);
    transform: scale(1.03);
}

.projectVideo {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.linkVideo {
    border: solid 1px var(--mainColor);
    border-radius: 30px;
    padding: 1rem;
    width: 240px; 
    height: 135px; 
}

.footer {
    margin-top: 6rem;
}

.footer div:first-of-type {
    margin: 1rem 0;
}

@media screen and (min-width: 390px) {
    .pictureContainer {
        width: 300px;
        height: 300px;
    }

    .linkVideo {
        width: 300px;
        height: 169px;
    }
}

@media screen and (min-width: 560px) {
    .pictureContainer {
        width: 400px;
        height: 400px;
    }

    .linkVideo {
        width: 450px; 
        height: 253px; 
    }
}

@media screen and (min-width: 769px) {
    .linkVideo {
        width: 560px; 
        height: 315px; 
    }
}

@media screen and (min-width: 992px) {
    .projectContainer {
        grid-template-columns: 1fr 2fr;
        justify-items: start;
    }

    .pictureContainer {
        order: unset;
    }

    .description {
        margin-top: 2rem;
        margin-left: 2rem;
    }

    .linkWeb {
        margin-left: 2rem;
    }
}

@media screen and (min-width: 1024px) {
    .linkVideo {
        width: 840px; 
        height: 472.5px; 
    }
}