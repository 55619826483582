.root {
    padding: 2rem;
    margin-bottom: 3rem;
}

.root h2 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 3.5rem;
}

.rootLoading {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    margin-bottom: 3rem;
}

.loadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
}

.projectsList {
    display: flex;
    padding: 0 1rem;
}

.slider {
    width: 100%;
}

li button:before {
    font-size: 10px !important;
}

.projectContainer {
    color: var(--mainColor);
    border: solid 1px var(--mainColor);
    border-radius: 30px;
    text-decoration: none;
    cursor: pointer;
    transition: 0.4s ease-in-out;
}

.projectContainer:hover {
    border: solid 1px var(--secondColor);
    transform: scale(1.03);
    box-shadow: 0px 0px 15px #233A4410;
}

.thumbnailContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.thumbnail {
    border-radius: 25px;
    width: 90%;
    margin: 1rem;
}

.projectContainer h3,
.projectContainer p {
    text-align: center;
    padding: 0 2rem;
}

.projectContainer p:last-of-type {
    padding-bottom: 2rem;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    padding: 2rem;
}

.showAllButton {
    color: var(--mainColor);
    background-color: transparent;
    border: solid 1px var(--mainColor);
    border-radius: 30px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.showAllButton:hover {
    color: var(--secondColor);
    transform: scale(1.03);
}

@media screen and (min-width: 992px) {
    .root {
        padding: 0 5rem 4rem 4rem;
    }

    .projectsList {
        padding-right: 2rem;
    }
}