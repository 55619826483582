.root {
    display: flex;
    flex-direction: column;
    padding: 2rem;
}

.root h2 {
    font-size: 2rem;
    text-align: center;
}

.root h3 {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
}

.descriptionContainer {
    display: grid;
    align-items: center;
    gap: 1rem;
}

.descriptionPicture {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.picture {
    width: 250px;
    height: 250px;
}

.descriptionContainerText {
    padding: 0;
}

.descriptionContainerText > h2 {
    margin-top: 0;
}

.pdfLink {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 2rem;
}

.pdfLink img {
    width: 25px;
    height: auto;
}

.pdfLink a {
    color: var(--mainColor);
    text-decoration: none;
    position: relative;
}

.pdfLink a:hover {
    color: var(--secondColor);
}

.linksvgline {
    position: absolute;
    top: 175%;
    left: 0;
    overflow: hidden;
    margin: 0;
    width: 100%;
    transition: stroke-dashoffset 0.3s ease-in-out;
    transform: translateY(-90%);
    fill: none;
    stroke: var(--secondColor);
    stroke-width: 10;
    stroke-linecap: round;
    stroke-dasharray: 400px; 
    stroke-dashoffset: 400px;
}

.pdfLink a:hover .linksvgline {
    stroke-dashoffset: 0;
}

.langageContainer {
    margin-top: 4rem;
}

.langageContainer h3 {
    margin-bottom: 3rem;
}

.linksList {
    display: grid;
    gap: 2rem;
    padding: 0;
}

.linksListElement {
    border: solid 1px var(--mainColor);
    border-radius: 30px;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    transition: 0.4s ease-in-out;
}

.linksListElement:hover {
    border: solid 1px var(--secondColor);
    transform: scale(1.04);
    box-shadow: 0px 0px 15px #233A4410;
}

.icon {
    width: 60px;
    height: auto;
}

.listTitle {
    font-size: 0.9rem;
    text-align: center;
    margin: 0.5rem 0;
}

.content {
    text-align: center;
    margin: 0.5rem 0 1rem 0;
}

@media screen and (min-width: 669px) {
    .picture {
        width: 300px;
        height: 300px;
    }    

    .linksList {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 992px) {
    .root {
        padding: 2rem 5rem 4rem 4rem;
    }

    .root h2 {
        text-align: left;
    }

    .descriptionContainer {
        grid-template-columns: 1fr auto;
    }

    .descriptionPicture {
        margin: 0;
    }
    
    .descriptionContainerText {
        padding: 0 2rem 0 3.5rem;
    }

    .linksList {
        grid-template-columns: repeat(4, 1fr);
    }
}